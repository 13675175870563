import axios from 'axios';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import styled from 'styled-components';

import { ATTR_INFO_ABOUT_BOX } from 'Shared/Order';

import { Context } from '../../Context';
import { OPTIONS } from '../Shared/Options';

const Box = ({ order }) => {
   const { refetch } = useContext(Context);
   const [disabledSelect, setDisabledSelect] = useState(true);
   const boxInfoObj = order.attributes.find((attr) => attr.key === ATTR_INFO_ABOUT_BOX) ?? null;
   const boxInfoValue = boxInfoObj ? JSON.parse(boxInfoObj.value) : '';

   const formik = useFormik({
      initialValues: {
         box: '',
      },
      onSubmit: (values) => {
         axios
            .put(`/paint-shop/orders/${order.id}/info-about-box`, {
               info: values.box,
            })
            .then(() => {
               refetch();
            });
      },
   });

   const boxOptions = OPTIONS.map((o) => (
      <option key={o.value} value={o.value}>
         {o.label}
      </option>
   ));

   return (
      <Container onSubmit={formik.handleSubmit}>
         <label htmlFor="box">
            Pudełko
            <select
               id="box"
               className="form-control"
               name="box"
               onChange={(e) => {
                  formik.handleChange(e);
                  formik.submitForm();
                  setDisabledSelect(true);
               }}
               onBlur={formik.handleBlur}
               value={formik.values.box || boxInfoValue}
               disabled={disabledSelect}
            >
               <option value="" />
               {boxOptions}
            </select>
         </label>
         <EditBtn
            className="btn btn-success"
            type="button"
            onClick={() => setDisabledSelect(false)}
         >
            Edytuj
         </EditBtn>
      </Container>
   );
};

const EditBtn = styled.button`
   margin-top: 1rem;
`;

const Container = styled.form`
   display: flex;
   flex-direction: column;
   position: relative;
`;

export default Box;
