import axios from 'axios';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import Calendar from 'react-calendar';

import CloseCalendarButton from 'Shared/CloseCalendarButton';

const PlannedToTransport = ({ finalPalette }) => {
   const { submitForm } = useFormikContext();
   const [show, setShow] = useState(false);
   const [date, setDate] = useState(finalPalette?.sendingPlannedAt);
   const sendingPlannedAt = finalPalette?.sendingPlannedAt;

   const onClick = () => {
      setShow(true);
   };

   const planSending = async () => {
      await axios.put(`/warehouse/final-palettes/${finalPalette.id}/sending-planned-at`, {
         date,
      });
      setShow(false);
      submitForm();
   };
   const onChange = (d) => {
      const formattedDate = dayjs(d).format('YYYY-MM-DD');
      setDate(formattedDate);
   };

   return (
      <div>
         {sendingPlannedAt && (
            <div>
               Wysyłka planowana na:
               <br /> {sendingPlannedAt}
            </div>
         )}
         {!show && (
            <button className="btn btn-success" onClick={() => onClick()} type="button">
               {sendingPlannedAt ? 'Rozplanuj ponownie' : 'Planowanie transportu'}
            </button>
         )}
         {show && (
            <div>
               <CloseCalendarButton setShow={setShow} />
               <Calendar onChange={onChange} className="sm" locale="pl" />
               <button className="btn btn-success" onClick={planSending} type="button">
                  Zaplanuj
               </button>
            </div>
         )}
      </div>
   );
};

export default PlannedToTransport;
