import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import {
   TYPE_GATE_SELF_SUPPORTING,
   TYPE_GATE_SELF_SUPPORTING_LONG,
   TYPE_CONCRETE,
   paletteTypes,
   guessPaletteType,
   shouldRenderProductsInPaletteConstructionAndPoles,
} from 'Shared/Warehouse/Palette';
import { getProductName } from 'Shared/Warehouse/Products';

const getPaletteLength = (palette) => {
   if (!palette?.orderParts?.[0]?.products?.length) {
      return 0;
   }

   return (
      palette.orderParts[0].products.reduce((max, curr) => {
         const maxWidth = max?.originalProduct?.rawArray?.szerokosc || 0;
         const currWidth = curr?.originalProduct?.rawArray?.szerokosc || 0;

         return +currWidth > +maxWidth ? curr : max;
      }).originalProduct?.rawArray?.szerokosc || ''
   );
};

const getConcreteSlabAmount = (palette) => {
   return palette.orderParts[0].products.reduce((acc, curr) => {
      return acc + (curr.amount || 0);
   }, 0);
};

const getPaletteWidth = (palette) => {
   if (getConcreteSlabAmount(palette) < 10) {
      return '800';
   }
   return '1000';
};

const paletteLength = (palette) =>
   palette.type === TYPE_CONCRETE ? getPaletteLength(palette) : palette.length;
const paletteWidth = (palette) =>
   palette.type === TYPE_CONCRETE ? getPaletteWidth(palette) : palette.width;

const renderProducts = (
   parts,
   groupWithConstructionAndPolesPalette,
   nextPaletteIsConstructionAndPoles,
) => {
   if (
      shouldRenderProductsInPaletteConstructionAndPoles(
         groupWithConstructionAndPolesPalette,
         nextPaletteIsConstructionAndPoles,
      )
   ) {
      const products = parts.map((op) => {
         return (
            <ul key={op.id}>
               {op.products.map((p) => {
                  return (
                     <li key={p.id}>
                        {p.amount} x {getProductName(p)}
                     </li>
                  );
               })}
            </ul>
         );
      });
      return products;
   }
   return 'Paleta słupów';
};

const PaletteForm = ({
   palette,
   index,
   groupWithConstructionAndPolesPalette,
   nextPaletteIsConstructionAndPoles,
}) => {
   const formik = useFormikContext();

   useEffect(() => {
      const paletteWidthValue = paletteWidth(palette);
      const paletteLengthValue = paletteLength(palette);
      if (paletteWidthValue) {
         formik.setFieldValue(`palettes[${index}].width`, paletteWidthValue);
      }
      if (paletteLengthValue) {
         formik.setFieldValue(`palettes[${index}].length`, paletteLengthValue);
      }
   }, []);

   const products = renderProducts(
      palette.orderParts,
      groupWithConstructionAndPolesPalette,
      nextPaletteIsConstructionAndPoles,
   );
   const ordersPublicIds = palette.orderParts.map((op) => {
      return (
         <li key={op.id}>
            <ZamowieniaLink
               displayId={op.order.zamowieniaId}
               zamowieniaId={op.order.zamowieniaId}
            />
         </li>
      );
   });
   const disabledInConcreteType = palette.type === TYPE_CONCRETE;
   const disabled = palette.type === TYPE_GATE_SELF_SUPPORTING;
   const paletteType = guessPaletteType(
      groupWithConstructionAndPolesPalette,
      paletteTypes[palette.type],
      nextPaletteIsConstructionAndPoles,
   );
   return (
      <table className="table table-hover">
         <thead>
            <tr>
               <th>Część</th>
               <th>Zamówienia</th>
               <th>Wysokość</th>
               <th>Szerokość</th>
               <th>Długość</th>
               {groupWithConstructionAndPolesPalette && nextPaletteIsConstructionAndPoles && <th />}
               {groupWithConstructionAndPolesPalette && !nextPaletteIsConstructionAndPoles && (
                  <>
                     <th>Ilość słupów</th>
                     <th>Ilość</th>
                  </>
               )}
               <th>Typ</th>
               <th>Produkty</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{palette.publicId}</td>
               <td>
                  <ul className="list-unstyled">{ordersPublicIds}</ul>
               </td>
               <td>
                  <input
                     type="text"
                     id={`palettes[${index}].height`}
                     name={`palettes[${index}].height`}
                     className="form-control"
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={palette.height || ''}
                     disabled={disabled}
                  />
                  {palette.type === TYPE_GATE_SELF_SUPPORTING_LONG && (
                     <div>
                        <input
                           type="text"
                           id={`palettes[${index}].height2`}
                           name={`palettes[${index}].height2`}
                           className="form-control"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={palette.height2 || ''}
                           disabled={disabled}
                           placeholder="Wysokość 2"
                        />
                        <input
                           type="text"
                           id={`palettes[${index}].height3`}
                           name={`palettes[${index}].height3`}
                           className="form-control"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={palette.height3 || ''}
                           disabled={disabled}
                           placeholder="Wysokość 3"
                        />
                     </div>
                  )}
               </td>
               <td>
                  <input
                     type="text"
                     id={`palettes[${index}].width`}
                     name={`palettes[${index}].width`}
                     className="form-control"
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={paletteWidth(palette) || ''}
                     disabled={disabled || disabledInConcreteType}
                  />
                  {palette.type === TYPE_GATE_SELF_SUPPORTING_LONG && (
                     <div>
                        <input
                           type="text"
                           id={`palettes[${index}].width2`}
                           name={`palettes[${index}].width2`}
                           className="form-control"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={palette.width2 || ''}
                           disabled={disabled}
                           placeholder="Szerokość 2"
                        />
                        <input
                           type="text"
                           id={`palettes[${index}].width3`}
                           name={`palettes[${index}].width3`}
                           className="form-control"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={palette.width3 || ''}
                           disabled={disabled}
                           placeholder="Szerokość 3"
                        />
                     </div>
                  )}
               </td>
               <td>
                  <input
                     type="text"
                     id={`palettes[${index}].length`}
                     name={`palettes[${index}].length`}
                     className="form-control"
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={paletteLength(palette) || ''}
                     disabled={disabled || disabledInConcreteType}
                  />
               </td>
               {groupWithConstructionAndPolesPalette && nextPaletteIsConstructionAndPoles && <td />}
               {groupWithConstructionAndPolesPalette && !nextPaletteIsConstructionAndPoles && (
                  <>
                     <td>
                        <input
                           type="text"
                           id={`palettes[${index}].numberOfPoles`}
                           name={`palettes[${index}].numberOfPoles`}
                           className="form-control"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={palette.numberOfPoles || ''}
                        />
                     </td>
                     <td>
                        <input
                           type="text"
                           id={`palettes[${index}].numberOfPalettes`}
                           name={`palettes[${index}].numberOfPalettes`}
                           className="form-control"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={palette.numberOfPalettes || ''}
                        />
                     </td>
                  </>
               )}
               <td>{paletteType} </td>
               <td>{products}</td>
            </tr>
         </tbody>
      </table>
   );
};

export default PaletteForm;
