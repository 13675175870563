import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';

import { getProductName } from '../Products';

import PostExtraLabels from './PostExtraLabels/PostExtraLabels';

const isDimmensionDefined = (palette, dimmension) => {
   return typeof palette[dimmension] !== 'undefined' && palette[dimmension] !== '';
};

const PaletteLabel = ({ closePaletteLabelModal, groupId, palettes, printedLabel }) => {
   const currentTime = new Date();
   const componentRef = useRef();
   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      onAfterPrint: () => printedLabel(),
   });

   return (
      <Modal className="modal" tabIndex="-1" role="dialog">
         <div className="modal-dialog modal-xl" role="document">
            <Container className="modal-content">
               <div>
                  <Header>
                     <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={closePaletteLabelModal}
                     />
                  </Header>
                  <LabelWrapper>
                     <LabelContainer ref={componentRef}>
                        {palettes.map((palette) => {
                           return (
                              <LabelPrintable key={palette.id}>
                                 <LabelContent>
                                    <LogoContainer>
                                       <Logo src="vectors/konsport_logo_primary.svg" alt="" />
                                    </LogoContainer>
                                    <div>
                                       <div>
                                          <b>Klient: </b>
                                          {palette.orderParts[0].order.companyName}
                                       </div>
                                       <div>
                                          <b>Id grupy: </b>
                                          {groupId}
                                       </div>
                                       <div>
                                          <b>Część: </b>
                                          {palette.publicId}
                                       </div>
                                    </div>
                                    <PaletteInfo>
                                       {isDimmensionDefined(palette, 'width') && (
                                          <Section>
                                             <b>Szerokość: </b>
                                             {palette.width} mm
                                          </Section>
                                       )}
                                       {isDimmensionDefined(palette, 'height') && (
                                          <Section>
                                             <b>Wysokość: </b>
                                             {palette.height} mm
                                          </Section>
                                       )}
                                       {isDimmensionDefined(palette, 'length') && (
                                          <Section>
                                             <b>Długość: </b>
                                             {palette.length} mm
                                          </Section>
                                       )}
                                       <Section>
                                          <b>Spakowano: </b>
                                          {dayjs(currentTime).format('HH:mm  DD-MM-YYYY')}
                                       </Section>
                                    </PaletteInfo>

                                    <ProductsContainer>
                                       <hr />
                                       {palette.orderParts.map((op) => {
                                          return (
                                             <div key={op.id}>
                                                <b>Paleta: </b>
                                                {op.publicId}
                                                <br />
                                                <List>
                                                   {op.products.map((p) => {
                                                      return (
                                                         <li key={p.id}>
                                                            <Section>
                                                               <b>{p.amount}x </b>
                                                               {getProductName(p)}
                                                            </Section>
                                                         </li>
                                                      );
                                                   })}
                                                </List>
                                             </div>
                                          );
                                       })}
                                    </ProductsContainer>
                                 </LabelContent>
                                 <Footnote>
                                    Etykieta generowana jest w momencie zakończenia wszelki prac
                                    związanych z przygotowaniem produktu do spakowania na palecie
                                    transportowej. Data spakowana na etykecie może nieznacznie
                                    różnić się od daty widocznej w zakładce “Spakowane do wysyłki” w
                                    sekcji palety.
                                 </Footnote>
                              </LabelPrintable>
                           );
                        })}
                        <PostExtraLabels palettes={palettes} groupId={groupId} />
                     </LabelContainer>
                  </LabelWrapper>
                  <Footer>
                     <Button
                        type="button"
                        className="btn btn-info"
                        data-dismiss="modal"
                        onClick={handlePrint}
                     >
                        <FontAwesomeIcon icon={faPrint} />
                        Drukuj etykietę
                     </Button>
                  </Footer>
               </div>
            </Container>
         </div>
      </Modal>
   );
};

const Logo = styled.img`
   @media print {
      height: 40px;
   }
`;

const Button = styled.button`
   color: #fff;
   &:hover,
   &:first-child:active {
      color: #fff;
   }
   .svg-inline--fa {
      margin-right: 0.5rem;
   }
`;

const Header = styled.div`
   display: flex;
   justify-content: flex-end;
   padding: 20px;
`;

const LogoContainer = styled.div`
   display: none;
   margin-bottom: 20px;

   @media print {
      display: inherit;
      margin-bottom: 30px;
   }
`;

const PaletteInfo = styled.div`
   margin-top: 0.5rem;

   @media print {
      margin-top: 1rem;
   }
`;

const Footnote = styled.div`
   display: none;

   @media print {
      display: inherit;
      align-self: flex-end;
      font-size: 0.8rem;
      font-style: italic;
   }
`;

const LabelWrapper = styled.div`
   display: flex;
   justify-content: space-around;
`;

const LabelContainer = styled.div`
   display: flex;
   flex-direction: column;
`;

const LabelContent = styled.div`
   flex-grow: 1;
`;

const Footer = styled.div`
   display: flex;
   justify-content: center;
   padding: 20px;
`;

const ProductsContainer = styled.div`
   margin-top: 1rem;
`;

const List = styled.ul`
   list-style-type: none;
   padding-left: 0.5rem;
`;

const Section = styled.div`
   font-style: italic;
   font-size: 1.2rem;
`;

const LabelPrintable = styled.div`
   page-break-after: always;
   display: flex;
   flex-direction: column;

   @media print {
      @page {
      size: A5;  
      }
   }
      padding: 20px;
      width: 148mm; 
      height: 210mm;
      font-size: 2rem;
      padding: 40px;
   }

   @media screen {
      padding: 10px;
      height: auto;
      width: auto;
   }
`;

const Container = styled.div`
   overflow-y: auto;
   max-height: calc(100vh - 1.75rem);
`;

const Modal = styled.div`
   display: block;
   background-color: rgba(0, 0, 0, 0.9);
`;

export default PaletteLabel;
