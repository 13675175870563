import styled from 'styled-components';

import {
   ATTR_INFO_ABOUT_BOX,
   ATTR_INFO_ABOUT_EQUIPMENT,
   attribute as orderAttribute,
} from 'Shared/Order';
import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import {
   guessPaletteType,
   shouldRenderProductsInPaletteConstructionAndPoles,
   attribute as paletteAttribute,
} from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';

import PlannedToTransport from './PlannedToTransport';

const FinalPalette = ({
   finalPalette,
   groupWithConstructionAndPolesPalette,
   nextPaletteIsConstructionAndPoles,
}) => {
   const paletteType = guessPaletteType(
      groupWithConstructionAndPolesPalette,
      finalPalette.type,
      nextPaletteIsConstructionAndPoles,
   );
   const renderProductsInPaletteConstructionAndPoles =
      shouldRenderProductsInPaletteConstructionAndPoles(
         groupWithConstructionAndPolesPalette,
         nextPaletteIsConstructionAndPoles,
      );
   const parts = finalPalette.orderParts.map((p) => {
      return (
         <tr key={p.id}>
            <td>
               <ZamowieniaLink
                  displayId={p.order.zamowieniaId}
                  zamowieniaId={p.order.zamowieniaId}
               />
            </td>
            <td>{orderAttribute(p.order.attributes, ATTR_INFO_ABOUT_EQUIPMENT)}</td>
            <td>{orderAttribute(p.order.attributes, ATTR_INFO_ABOUT_BOX)}</td>
            <td>
               {renderProductsInPaletteConstructionAndPoles && <Products products={p.products} />}
               {!renderProductsInPaletteConstructionAndPoles && 'Paleta słupów'}
            </td>
         </tr>
      );
   });
   return (
      <table className="table table-hover table-sm">
         <thead>
            <tr>
               <th>Paleta</th>
               <th>Pakowane</th>
               <th>Planowanie transportu</th>
               <th>Części zamówień</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>
                  <Ul>
                     <Li>
                        <span>Część: </span>
                        {finalPalette.publicId || ''}
                     </Li>
                     <Li>
                        <span>Szerokość: </span>
                        {paletteAttribute('width', finalPalette.attributes)}
                     </Li>
                     <Li>
                        <span>Wysokość: </span>
                        {paletteAttribute('height', finalPalette.attributes)}
                     </Li>
                     <Li>
                        <span>Długość: </span>
                        {paletteAttribute('length', finalPalette.attributes)}
                     </Li>
                     <Li>
                        <span>Waga: </span>
                        {paletteAttribute('weight', finalPalette.attributes)}
                     </Li>
                     {groupWithConstructionAndPolesPalette &&
                        !nextPaletteIsConstructionAndPoles && (
                           <Li>
                              <span>Ilość słupów: </span>
                              {paletteAttribute('numberOfPoles', finalPalette.attributes)}
                           </Li>
                        )}
                     <Li>
                        <span>Typ: </span>
                        {paletteType}
                     </Li>
                  </Ul>
               </td>
               <td>
                  <Ul>
                     <Li>
                        <span>Przez: </span>
                        {finalPalette.packedByFilledInManually || ''}
                     </Li>
                     <Li>
                        <span>Kiedy: </span> {finalPalette.packedAt || ''}
                     </Li>
                  </Ul>
               </td>
               <td>
                  <PlannedToTransport finalPalette={finalPalette} />
               </td>
               <td>
                  <table className="table table-hover table-sm">
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Osprzęt</th>
                           <th>Pudełko</th>
                           <th>Produkty</th>
                        </tr>
                     </thead>
                     <tbody>{parts}</tbody>
                  </table>
               </td>
            </tr>
         </tbody>
      </table>
   );
};

export default FinalPalette;

const Ul = styled.ul`
   padding-left: 0rem;
`;

const Li = styled.li`
   list-style: none;
`;
