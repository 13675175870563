import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';

import {
   STATUS_TO_BE_PACKED,
   STATUS_PACKED,
   STATUS_TO_BE_SEND,
   STATUS_SENT,
} from 'Shared/Warehouse/OrderPartsGroup';
import ShowHideButton from 'Shared/Warehouse/ShowHideButton';

import GroupWithFinalPalettes from './GroupWithFinalPalettes';
import GroupWithoutFinalPalettes from './GroupWithoutFinalPalettes';

const SearchGroupByZamowieniaId = () => {
   const [render, setRender] = useState(true);
   const [rendered, setRendered] = useState([]);
   const formik = useFormik({
      initialValues: {
         zamowieniaId: '',
      },
      onSubmit: async ({ zamowieniaId }) => {
         const { data } = await axios.get(`/warehouse/groups-by-zamowienia-id/${zamowieniaId}`);
         if (!data.groups.length) {
            setRendered([]);
            return;
         }
         const r = data.groups.map((g) => {
            switch (g.status) {
               case STATUS_TO_BE_PACKED:
               case STATUS_PACKED:
               case STATUS_TO_BE_SEND:
               case STATUS_SENT:
                  return <GroupWithFinalPalettes key={g.id} group={g} />;
               default:
                  return <GroupWithoutFinalPalettes key={g.id} group={g} />;
            }
         });
         setRendered(r);
      },
   });

   return (
      <Container>
         <FormikProvider value={formik}>
            <div>
               <h2 className="h3">
                  Wyszukiwanie po numerze zamówienia
                  <ShowHideButton render={render} onClick={() => setRender((p) => !p)} />
               </h2>
            </div>
            {render && (
               <>
                  <Form onSubmit={formik.handleSubmit}>
                     <div>
                        <label htmlFor="zamowieniaId">
                           Numer zamówienia
                           <input
                              type="text"
                              className="form-control"
                              name="zamowieniaId"
                              placeholder="Numer zamówienia"
                              value={formik.values.zamowieniaId}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />
                        </label>
                        <SearchButton type="submit" className="btn btn-success">
                           Szukaj
                        </SearchButton>
                     </div>
                  </Form>
                  {!!(!rendered.length && formik.submitCount) && (
                     <div>
                        <span>Nic nie znaleziono</span>
                     </div>
                  )}
                  {!!rendered.length && (
                     <Table className="table table-hover">
                        <thead>
                           <tr>
                              <th>Grupa</th>
                              <th>Zamówienia</th>
                           </tr>
                        </thead>
                        <tbody>{rendered}</tbody>
                     </Table>
                  )}
               </>
            )}
         </FormikProvider>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
   margin-bottom: 1rem;
`;

const Form = styled.form`
   display: flex;
   align-items: flex-end;
`;

const Table = styled.table`
   margin-top: 1rem;
`;

const SearchButton = styled.button`
   margin-left: 1rem;
`;

export default SearchGroupByZamowieniaId;
