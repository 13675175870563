import {
   ATTR_INFO_ABOUT_BOX,
   ATTR_INFO_ABOUT_EQUIPMENT,
   attribute as orderAttribute,
} from 'Shared/Order';
import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import {
   guessPaletteType,
   shouldRenderProductsInPaletteConstructionAndPoles,
   attribute as paletteAttribute,
} from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';

const FinalPalette = ({
   finalPalette,
   groupWithConstructionAndPolesPalette,
   nextPaletteIsConstructionAndPoles,
}) => {
   const paletteType = guessPaletteType(
      groupWithConstructionAndPolesPalette,
      finalPalette.type,
      nextPaletteIsConstructionAndPoles,
   );
   const renderProductsInPaletteConstructionAndPoles =
      shouldRenderProductsInPaletteConstructionAndPoles(
         groupWithConstructionAndPolesPalette,
         nextPaletteIsConstructionAndPoles,
      );
   const parts = finalPalette.orderParts.map((p) => {
      return (
         <tr key={p.id}>
            <td>
               <ZamowieniaLink
                  displayId={p.order.zamowieniaId}
                  zamowieniaId={p.order.zamowieniaId}
               />
            </td>
            <td>{orderAttribute(p.order.attributes, ATTR_INFO_ABOUT_EQUIPMENT)}</td>
            <td>{orderAttribute(p.order.attributes, ATTR_INFO_ABOUT_BOX)}</td>
            <td>
               {renderProductsInPaletteConstructionAndPoles && <Products products={p.products} />}
               {!renderProductsInPaletteConstructionAndPoles && 'Paleta słupów'}
            </td>
         </tr>
      );
   });

   return (
      <table className="table table-hover table-sm">
         <thead>
            <tr>
               <th>#</th>
               <th>Szerokość</th>
               <th>Wysokość</th>
               <th>Długość</th>
               <th>Waga</th>
               <th>Typ</th>
               <th>Części zamówień</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{finalPalette.publicId || ''}</td>
               <td>{paletteAttribute('width', finalPalette.attributes)}</td>
               <td>{paletteAttribute('height', finalPalette.attributes)}</td>
               <td>{paletteAttribute('length', finalPalette.attributes)}</td>
               <td>{paletteAttribute('weight', finalPalette.attributes)}</td>
               <td>{paletteType}</td>
               <td>
                  <table className="table table-hover table-sm">
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Osprzęt</th>
                           <th>Pudełko</th>
                           <th>Produkty</th>
                        </tr>
                     </thead>
                     <tbody>{parts}</tbody>
                  </table>
               </td>
            </tr>
         </tbody>
      </table>
   );
};

export default FinalPalette;
