import { faBox, faBoxOpen, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { isNextPaletteConstructionAndPoles } from 'Shared/Warehouse/Palette';
import { CompanyName } from 'Shared/Warehouse/Warehouse';
import { Loader } from 'ui/Loader';

import { isGroupWithConstructionAndPolesPalette } from '../OrderPartsGroup';

import LastIssues from './LastIssues';
import PaletteForm from './PaletteForm';
import PaletteLabel from './PaletteLabel';

const validate = (values) => {
   let errors = {};
   if (!values.packedByFilledInManually) {
      errors = {
         ...errors,
         packedByFilledInManually: 'Nieprawidłowa wartość.',
      };
   }

   return errors;
};

const availableAttributes = [
   'amount',
   'width',
   'height',
   'length',
   'width2',
   'width3',
   'height2',
   'height3',
   'numberOfPoles',
];

const isPaletteTypeConcrete = (palette) => {
   return palette.type === 'CONCRETE';
};

const getPaletteWeight = (palette) => {
   const weight =
      palette.orderParts[0].products.reduce((acc, p) => {
         if (
            p.originalProduct.rawArray?.beton &&
            p.originalProduct.rawArray.beton.includes('Deska betonowa')
         ) {
            return acc + p.amount * (p.originalProduct.rawArray?.waga || 0);
         }
         return acc;
      }, 0) + 15 || '';

   return String(weight);
};

const attributesFromPalette = (palette) => {
   return Object.entries(palette).reduce((acc, [key, value]) => {
      if (availableAttributes.includes(key)) {
         // eslint-disable-next-line no-param-reassign
         acc = [
            ...acc,
            {
               key,
               value,
            },
         ];
      }
      return acc;
   }, []);
};

const FinalPaletteModal = ({ group, close, refetch }) => {
   const [paletteLabelModal, setPaletteLabelModal] = useState(false);
   const [printedLabel, setPrintedLabel] = useState(false);

   const formik = useFormik({
      initialValues: {
         palettes: [],
         packedByFilledInManually: '',
      },
      onSubmit: (values) => {
         const palettes = values.palettes.map((p) => {
            const orderParts = p.orderParts.map((op) => {
               return {
                  id: op.id,
               };
            });
            const attributes = attributesFromPalette(p);
            if (isPaletteTypeConcrete(p)) {
               const weight = getPaletteWeight(p);
               if (weight) {
                  return {
                     id: p.id,
                     orderParts,
                     attributes: [...attributes, { key: 'weight', value: weight }],
                  };
               }
            }

            return {
               id: p.id,
               orderParts,
               attributes,
            };
         });
         const data = {
            palettes,
            packedByFilledInManually: values.packedByFilledInManually,
         };
         axios.post(`/warehouse/groups/${group.id}/pack-final-palettes`, data).then(() => {
            close();
            refetch();
         });
      },
      validate,
   });
   useEffect(() => {
      if (formik.values.palettes.length) return;
      const palettes = group.palettes.map((p) => {
         return {
            id: p.id,
            publicId: p.publicId,
            orderParts: p.orderParts,
            type: p.type,
         };
      });
      formik.setFieldValue('palettes', palettes);
   }, []);
   const groupWithConstructionAndPolesPalette = isGroupWithConstructionAndPolesPalette(group);
   const rendered = formik.values.palettes.map((p, i, arr) => {
      const next = arr[i + 1];
      const nextPaletteIsConstructionAndPoles = isNextPaletteConstructionAndPoles(next);
      return (
         <PaletteForm
            key={p.id}
            palette={p}
            index={i}
            groupWithConstructionAndPolesPalette={groupWithConstructionAndPolesPalette}
            nextPaletteIsConstructionAndPoles={nextPaletteIsConstructionAndPoles}
         />
      );
   });
   const disabled = formik.isSubmitting || Object.keys(formik.errors).length || !printedLabel;

   return (
      <Modal className="modal" tabIndex="-1" role="dialog">
         <div className="modal-dialog modal-xl" role="document">
            <Container className="modal-content">
               <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                     <div className="modal-header">
                        <h3 className="modal-title">
                           <span>Wysyłasz:&nbsp;</span>
                           <CompanyName
                              orderPartsGroup={group}
                              createdByExternalClient={
                                 group.palettes[0].orderParts[0].order.createdByExternalClient
                              }
                           />
                           <span>| Id grupy: {group.publicId}</span>
                        </h3>
                        <button
                           type="button"
                           className="btn-close"
                           data-bs-dismiss="modal"
                           aria-label="Close"
                           onClick={close}
                        />
                     </div>
                     <Body className="modal-body">
                        <Palettes>
                           <PalettesHeader>
                              <FontAwesomeIcon icon={faBoxOpen} />
                              Palety
                           </PalettesHeader>
                           <div>{rendered}</div>
                           <PackedByFilledInManuallyContainer>
                              <div>
                                 <label htmlFor="packedByFilledInManually">
                                    Spakowane przez
                                    <input
                                       type="text"
                                       name="packedByFilledInManually"
                                       id="packedByFilledInManually"
                                       className="form-control"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.packedByFilledInManually}
                                    />
                                 </label>
                              </div>
                           </PackedByFilledInManuallyContainer>
                        </Palettes>
                     </Body>
                     <FooterContainer className="modal-footer">
                        {paletteLabelModal && (
                           <PaletteLabel
                              groupId={group.publicId}
                              palettes={formik.values.palettes}
                              closePaletteLabelModal={() => setPaletteLabelModal(false)}
                              printedLabel={() => setPrintedLabel(true)}
                           />
                        )}
                        <LastIssues printedLabel={printedLabel} />
                        <ModalActions>
                           <Button
                              type="button"
                              className="btn btn-info"
                              data-dismiss="modal"
                              onClick={() => {
                                 setPaletteLabelModal(!paletteLabelModal);
                              }}
                           >
                              <FontAwesomeIcon icon={faPrint} />
                              Utwórz etykietę do druku
                           </Button>
                           <Button type="submit" className="btn btn-success" disabled={disabled}>
                              <FontAwesomeIcon icon={faBox} />
                              Spakowane na gotowo
                           </Button>
                           <button
                              type="button"
                              className="btn btn-danger"
                              data-dismiss="modal"
                              onClick={close}
                           >
                              Zamknij
                           </button>
                        </ModalActions>
                     </FooterContainer>
                  </form>
               </FormikProvider>
               {formik.isSubmitting && <Loader />}
            </Container>
         </div>
      </Modal>
   );
};

const Container = styled.div`
   overflow-y: auto;
   max-height: calc(100vh - 1.75rem);
`;

const Body = styled.div`
   & > div {
      margin-bottom: 1rem;
   }
`;

const FooterContainer = styled.div`
   display: flex;
   justify-content: space-between;
`;

const ModalActions = styled.div`
   .btn {
      margin-left: 1rem;
   }
`;

const Button = styled.button`
   color: #fff;
   &:hover,
   &:first-child:active {
      color: #fff;
   }
   .svg-inline--fa {
      margin-right: 0.5rem;
   }
`;

const Modal = styled.div`
   display: block;
   background-color: rgba(0, 0, 0, 0.9);
`;

const Palettes = styled.div`
   margin: 1rem 0;
`;

const PackedByFilledInManuallyContainer = styled.div`
   display: flex;
   justify-content: flex-end;
`;

const PalettesHeader = styled.h4`
   .svg-inline--fa {
      margin-right: 0.5rem;
   }
`;

export default FinalPaletteModal;
