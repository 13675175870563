import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

// eslint-disable-next-line import/no-cycle
import { colors } from 'deprecatedTheme/colors';
// eslint-disable-next-line import/no-cycle
import { TYPE_CONSTRUCTION_AND_POLES_PALETTE } from 'Shared/Warehouse/Palette';
// eslint-disable-next-line import/no-cycle
import { getColorForOrderGroupStatus } from 'Shared/Warehouse/Warehouse';

export const STATUS_TO_BE_PACKED_INTO_WAREHOUSE = 'TO_BE_PACKED_INTO_WAREHOUSE';
export const STATUS_PACKED_INTO_WAREHOUSE = 'PACKED_INTO_WAREHOUSE';
export const STATUS_TO_BE_PACKED = 'TO_BE_PACKED';
export const STATUS_PACKED = 'PACKED';
export const STATUS_TO_BE_SEND = 'TO_BE_SEND';
export const STATUS_SENT = 'SENT';

// Owijarka, zamówienia pakowane bezpośrednio po zejściu z lakierni
export const PACKING_PLACE_DIRECT = 'DIRECT';
// MAGAZYN
export const PACKING_PLACE_WAREHOUSE = 'WAREHOUSE';

export const packingPlaces = {
   [PACKING_PLACE_DIRECT]: 'na owijarce',
   [PACKING_PLACE_WAREHOUSE]: 'na magazynie',
};

export const packingPlacesForklift = {
   [PACKING_PLACE_DIRECT]: 'na owijarke',
   [PACKING_PLACE_WAREHOUSE]: 'na magazyn',
};

export const orderPartsGroupStatuses = {
   [STATUS_PACKED_INTO_WAREHOUSE]: 'Na strefie',
   [STATUS_PACKED]: 'Spakowane na gotowo',
   [STATUS_TO_BE_PACKED]: 'Do spakowania na gotowo',
   [STATUS_TO_BE_PACKED_INTO_WAREHOUSE]: 'Do spakowania na strefę',
   [STATUS_TO_BE_SEND]: 'Zakończone / Do wysłania',
   [STATUS_SENT]: 'Wysłane',
};

const OrderPartsGroupContainerStyled = styled.div`
   padding: 0.4rem;
   border: 4px solid ${(props) => (props.color ? props.color : colors.neutral70)};
   border-radius: 4px;
   margin-bottom: 0.5rem;
`;

export const OrderPartsGroupContainer = ({
   children,
   orderPartsGroup,
   createdByExternalClient,
   _ref,
}) => {
   const color = getColorForOrderGroupStatus(orderPartsGroup.status, createdByExternalClient);

   return (
      <OrderPartsGroupContainerStyled
         color={color}
         ref={_ref}
         createdByExternalClient={createdByExternalClient}
      >
         {children}
      </OrderPartsGroupContainerStyled>
   );
};

const ShowOrdersBtn = styled.button`
   padding: 0;
   line-height: 1;
   margin-right: 1rem;
   font-size: 1.4rem;

   svg {
      color: ${colors.neutral60};
   }
`;

export const ShowOrdersButton = ({ showOrders, setShowOrders }) => (
   <ShowOrdersBtn
      type="button"
      onClick={() => setShowOrders((prevShowOrders) => !prevShowOrders)}
      className="btn"
   >
      <FontAwesomeIcon icon={showOrders ? faAngleUp : faAngleDown} />
   </ShowOrdersBtn>
);

export const isGroupWithConstructionAndPolesPalette = (group) => {
   return group.palettes.some((p) => p.type === TYPE_CONSTRUCTION_AND_POLES_PALETTE);
};
