import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import {
   attribute,
   guessPaletteType,
   shouldRenderProductsInPaletteConstructionAndPoles,
} from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';

const renderProducts = (
   parts,
   groupWithConstructionAndPolesPalette,
   nextPaletteIsConstructionAndPoles,
) => {
   if (
      shouldRenderProductsInPaletteConstructionAndPoles(
         groupWithConstructionAndPolesPalette,
         nextPaletteIsConstructionAndPoles,
      )
   ) {
      const products = parts.map((op) => {
         return <Products key={op.id} products={op.products} />;
      });
      return products;
   }
   return 'Paleta słupów';
};

const FinalPalette = ({
   finalPalette,
   groupWithConstructionAndPolesPalette,
   nextPaletteIsConstructionAndPoles,
}) => {
   const paletteType = guessPaletteType(
      groupWithConstructionAndPolesPalette,
      finalPalette.type,
      nextPaletteIsConstructionAndPoles,
   );
   const ordersPublicIds = finalPalette.orderParts.map((op) => {
      return (
         <li key={op.id}>
            <ZamowieniaLink
               displayId={op.order.zamowieniaId}
               zamowieniaId={op.order.zamowieniaId}
            />
         </li>
      );
   });
   const products = renderProducts(
      finalPalette.orderParts,
      groupWithConstructionAndPolesPalette,
      nextPaletteIsConstructionAndPoles,
   );
   return (
      <table className="table table-hover table-sm">
         <thead>
            <tr>
               <th>Część</th>
               <th>Szerokość</th>
               <th>Wysokość</th>
               <th>Długość</th>
               <th>Waga</th>
               {groupWithConstructionAndPolesPalette && !nextPaletteIsConstructionAndPoles && (
                  <th>Ilość słupów</th>
               )}
               <th>Typ</th>
               <th>Pakowane przez</th>
               <th>Zamówienia / części zamówień</th>
               <th>Kiedy</th>
               <th>Produkty</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{finalPalette.publicId || ''}</td>
               <td>{attribute('width', finalPalette.attributes)}</td>
               <td>{attribute('height', finalPalette.attributes)}</td>
               <td>{attribute('length', finalPalette.attributes)}</td>
               <td>{attribute('weight', finalPalette.attributes)}</td>
               {groupWithConstructionAndPolesPalette && !nextPaletteIsConstructionAndPoles && (
                  <td>{attribute('numberOfPoles', finalPalette.attributes)}</td>
               )}
               <td>{paletteType}</td>
               <td>{finalPalette.packedByFilledInManually || ''}</td>
               <td>
                  <ul className="list-unstyled">{ordersPublicIds}</ul>
               </td>
               <td>{finalPalette.packedAt || ''}</td>
               <td>{products}</td>
            </tr>
         </tbody>
      </table>
   );
};

export default FinalPalette;
