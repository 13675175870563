export const ORDER_STATUS_DRAFT = 'DRAFT';

export const PRICE_TYPE_WITH_TAX = 'priceWithTax';
export const PRICE_TYPE_WITHOUT_TAX = 'priceWithoutTax';

export const DEFAULT_TAX_RATE = 23;

export const orderSelector = (state, orderId = null) => {
   if (!state.order.orders.length) return null;
   if (orderId) {
      return state.order.orders.find((order) => order.id === orderId);
   }

   return state.order.orders.find((order) => order.status === ORDER_STATUS_DRAFT);
};

export const ATTR_INFO_ABOUT_BOX = 'info-about-box';
export const ATTR_INFO_ABOUT_EQUIPMENT = 'info-about-equipment';

export const ATTRIBUTES_VALUE_POLISH = {
   YES: 'TAK',
   NO: 'NIE',
   NOT_APPLICABLE: 'NIE DOTYCZY',
   IN_PROGRESS: 'W TRAKCIE',
};

export const attribute = (attributes, key) => {
   if (!attributes[key]) return null;
   const v = JSON.parse(attributes[key]);
   if (!v) return null;
   if (!ATTRIBUTES_VALUE_POLISH[v]) return null;
   return ATTRIBUTES_VALUE_POLISH[v];
};
